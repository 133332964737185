<template>
  <div class="conGrad">
    <v-layout
      v-if="serviceData.top"
      wrap
      justify-center
      pa-sm-4
      pa-md-10
      pa-lg-16
    >
      <v-flex
        xs12
        sm12
        lg11
        xl8
        align-self-center
        py-4
        px-sm-0
        px-md-5
        px-lg-16
      >
        <v-card
          :min-height="$vuetify.breakpoint.name == 'xl' ? '80vh' : '60vh'"
          flat
          dark
          color="transparent"
        >
          <v-layout wrap justify-center pt-md-12 pt-xl-16>
            <v-flex
              xs12
              sm6
              md6
              lg6
              pl-3
              pr-3
              pl-sm-0
              pr-sm-4
              pl-md-0
              pr-md-8
              pt-12
              pt-sm-10
              pt-md-0
              pt-xl-16
              align-self-center
            >
              <v-layout wrap>
                <v-flex xs12 py-5 align-self-center text-left>
                  <span
                    :class="
                      $vuetify.breakpoint.name == 'sm' ||
                      $vuetify.breakpoint.name == 'xs'
                        ? 'con1xs'
                        : 'con1'
                    "
                    >{{ serviceData.top.title }}</span
                  >
                </v-flex>
                <v-flex pb-4 xs12 align-self-center text-left>
                  <span
                    :class="
                      $vuetify.breakpoint.name == 'sm' ||
                      $vuetify.breakpoint.name == 'xs'
                        ? 'con7xs'
                        : $vuetify.breakpoint.name == 'md'
                        ? 'con7md'
                        : 'con7'
                    "
                  >
                    {{ serviceData.top.topCaption }}
                  </span>
                </v-flex>
                <v-flex xs12 pb-6 align-self-center text-justify>
                  <span
                  v-html="serviceData.top.description"
                    :class="
                      $vuetify.breakpoint.name == 'sm' ||
                      $vuetify.breakpoint.name == 'xs'
                        ? 'con2xs'
                        : 'con22'
                    "
                  >
                
                  </span>
                </v-flex>
              
              </v-layout>
            </v-flex>

            <v-flex
              v-if="serviceData.top.image"
              xs12
              sm6
              md6
              lg6
              align-self-center
              pt-xl-16
              pl-md-8
              pl-3
              pr-3
            >
              <!-- <v-img :src="require('./../../assets/Home/mac.png')"></v-img> -->
              <v-img :src="webpSupported && item.image.split('.').pop() !== 'svg'? mediaURL+serviceData.top.image :mediaURLold+serviceData.top.image"></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["serviceData"],
  data() {
    return {
      webpSupported: true,
    }
  },
  created() {
    (async () => {
      // If browser doesn't have createImageBitmap, we can't use webp.
      if (!self.createImageBitmap) {
        this.webpSupported = false;
        return;
      }

      // Base64 representation of a white point image
      const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then(r => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      this.webpSupported = await createImageBitmap(blob).then(() => true, () => false);

    })();
  },
};
</script>

<style  scoped>
.conGrad {
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);
}
.graddy {
  background: transparent
    linear-gradient(
      100deg,
      #f5a624 0%,
      #f9b52c 44%,
      #fbba2f 59%,
      #fdc033 78%,
      #fdc033 78%,
      #ffc837 100%
    );
}
</style>