var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conGrad"},[_c('v-layout',{attrs:{"px-5":"","px-md-10":"","px-lg-15":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"px-0":"","px-md-0":"","px-lg-10":"","xs12":"","sm12":"","lg11":"","xl8":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-md-12":"","pt-xl-16":""}},[_c('v-flex',{attrs:{"xs12":"","pa-2":""}},[_c('v-layout',{attrs:{"wrap":"","pb-4":""}},_vm._l((_vm.services),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm6":"","md4":"","pt-8":"","px-sm-4":"","px-lg-6":"","py-2":""}},[_c('v-card',{attrs:{"dark":"","elevation":"4"},on:{"mouseover":function($event){item.color = true},"mouseleave":function($event){item.color = false},"click":function($event){return _vm.$router.push('/products?category=' + item._id)}}},[_c('v-img',{staticClass:"align-end",attrs:{"contain":_vm.webpSupported?true:false,"height":_vm.webpSupported?'auto':'500px',"src":_vm.webpSupported && item.image.split('.').pop() !== 'svg'
                      ? _vm.mediaURL + item.homeImage
                      : _vm.mediaURLold + item.homeImage},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)},[_c('v-card',{staticStyle:{"opacity":"0.8"},attrs:{"flat":"","color":"#000"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-sm-4":""}},[_c('v-flex',{attrs:{"xs12":"","py-4":"","align-self-center":"","text-center":""}},[(item.color == true)?_c('span',{key:i,staticClass:"bantitle555",staticStyle:{"color":"#ffac2c"}},[_c('b',[_vm._v(_vm._s(item.name))])]):_c('span',{key:i,staticClass:"bantitle555"},[_c('b',[_vm._v(_vm._s(item.name))])])]),_c('v-flex',{style:({
                          height:
                            _vm.$vuetify.breakpoint.name == 'xs'
                              ? '80px'
                              : '60px',
                        }),attrs:{"pb-4":"","pb-sm-0":"","xs12":"","align-self-center":"","text-center":""}},[_c('span',{staticClass:"bantitle444xs"},[_vm._v(_vm._s(item.title))])])],1)],1)],1)],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }