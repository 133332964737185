var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"px-5":"","px-md-10":"","px-lg-15":"","wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"px-0":"","px-md-0":"","px-lg-15":"","xs12":"","align-self-center":""}},[_c('v-card',{staticClass:"px-4",attrs:{"flat":"","color":"#FFF"}},[_c('v-layout',{style:({
            'min-height':
              _vm.$vuetify.breakpoint.name == 'xs'
                ? '30vh'
                : _vm.$vuetify.breakpoint.name == 'sm'
                ? '30vh'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '30vh'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '30vh'
                : '30vh',
          }),attrs:{"py-4":"","fill-height":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":"","pt-xl-16":"","pl-md-11":"","pl-3":"","pr-3":""}},[_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.servicesList),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm4":"","md4":"","lg3":"","pt-2":""}},[_c('span',[_c('v-icon',{attrs:{"size":"8","color":"#000"},domProps:{"textContent":_vm._s('mdi-checkbox-blank-circle')}}),_c('span',{staticClass:"pl-2",staticStyle:{"font-size":"15px","font-family":"BahnR"}},[_vm._v(_vm._s(item.name))])],1)])}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }