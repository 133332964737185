<template>
  <div style="padding-top: 0px">
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FF6907"
      spinner="bar-fade-scale"
    />
    <!-- <ServerError v-if="ServerError" /> -->
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <ServiceHeader :serviceData="serviceData" />
      </v-flex>
         <v-flex xs12>
        <Services/>
      </v-flex>
       <v-flex xs12>
        <ServicesList/>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";

import ServiceHeader from "@/components/Services/serviceHeader";
import Services from "@/components/Services/services";
import ServicesList from "@/components/Services/servicesList";
export default {
  data() {
    return {
      
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
       serviceData: {},
    }
  },
  components: {
    ServiceHeader,Services,ServicesList
  },
  mounted() {
    this.getData();
  },
  methods:{
  getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "services/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.serviceData = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
  
  }
</script>
