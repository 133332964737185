<template>
  <div>
    <v-layout px-5 px-md-10 px-lg-15 wrap justify-start>
      <v-flex px-0 px-md-0 px-lg-15 xs12 align-self-center>
        <v-card class="px-4" flat color="#FFF">
          <v-layout
            py-4
            :style="{
              'min-height':
                $vuetify.breakpoint.name == 'xs'
                  ? '30vh'
                  : $vuetify.breakpoint.name == 'sm'
                  ? '30vh'
                  : $vuetify.breakpoint.name == 'md'
                  ? '30vh'
                  : $vuetify.breakpoint.name == 'lg'
                  ? '30vh'
                  : '30vh',
            }"
            fill-height
            wrap
            justify-center
          >
            <v-flex xs12 align-self-center pt-xl-16 pl-md-11 pl-3 pr-3>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm4
                  md4 
                  lg3
                  pt-2
                  v-for="(item, i) in servicesList"
                  :key="i"
                >
                  <span>
                    <v-icon
                      size="8"
                      color="#000"
                      v-text="'mdi-checkbox-blank-circle'"
                    ></v-icon>
                    <span
                      class="pl-2"
                      style="font-size: 15px; font-family: BahnR"
                      >{{ item.name }}</span
                    >
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      servicesList: [],
    };
  },
  mounted() {
    this.getServiceData();
  },
  methods: {
    getServiceData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "services/serviceList/getlist",
      })
        .then((response) => {
          if (response.data.status) {
            this.servicesList = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style  scoped>
.conGrad {
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);
}
.graddy {
  background: transparent
    linear-gradient(
      100deg,
      #f5a624 0%,
      #f9b52c 44%,
      #fbba2f 59%,
      #fdc033 78%,
      #fdc033 78%,
      #ffc837 100%
    );
}
</style>