<template>
  <div class="conGrad">
    <v-layout px-5 px-md-10 px-lg-15 wrap justify-center>
      <v-flex px-0 px-md-0 px-lg-10 xs12 sm12 lg11 xl8 align-self-center>
        <v-layout wrap justify-center pt-md-12 pt-xl-16>
          <v-flex xs12 pa-2>
            <v-layout wrap pb-4>
              <v-flex
                xs12
                sm6
                md4
                pt-8
                px-sm-4
                px-lg-6
                py-2
                v-for="(item, i) in services"
                :key="i"
              >
                <v-card
                  dark
                  elevation="4"
                  @mouseover="item.color = true"
                  @mouseleave="item.color = false"
                  @click="$router.push('/products?category=' + item._id)"
                >
                  <v-img
                    class="align-end"
                    :contain="webpSupported?true:false"
                    :height="webpSupported?'auto':'500px'"
                    :src="
                      webpSupported && item.image.split('.').pop() !== 'svg'
                        ? mediaURL + item.homeImage
                        : mediaURLold + item.homeImage
                    "
                  >
                    <template v-slot:placeholder>
                      <ImageLoader />
                    </template>
                    <v-card flat color="#000" style="opacity: 0.8">
                      <v-layout wrap justify-center py-sm-4>
                        <v-flex xs12 py-4 align-self-center text-center>
                          <span
                            v-if="item.color == true"
                            style="color: #ffac2c"
                            :key="i"
                            class="bantitle555"
                          >
                            <b>{{ item.name }}</b>
                          </span>
                          <span v-else :key="i" class="bantitle555">
                            <b>{{ item.name }}</b>
                          </span>
                        </v-flex>
                        <v-flex
                          :style="{
                            height:
                              $vuetify.breakpoint.name == 'xs'
                                ? '80px'
                                : '60px',
                          }"
                          pb-4
                          pb-sm-0
                          xs12
                          align-self-center
                          text-center
                        >
                          <span class="bantitle444xs">{{ item.title }}</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-img>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      ServerError: false,
      services: [],
      show: false,
      webpSupported: true,
    };
  },
  created() {
    (async () => {
      // If browser doesn't have createImageBitmap, we can't use webp.
      if (!self.createImageBitmap) {
        this.webpSupported = false;
        return;
      }

      // Base64 representation of a white point image
      const webpData =
        "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then((r) => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      this.webpSupported = await createImageBitmap(blob).then(
        () => true,
        () => false
      );
    })();
  },
  mounted() {
    this.getCategoryData();
  },
  methods: {
    getCategoryData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "product/category/getlist",
      })
        .then((response) => {
          if (response.data.status) {
            this.services = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style  scoped>
.conGrad {
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);
}
.graddy {
  background: transparent
    linear-gradient(
      100deg,
      #f5a624 0%,
      #f9b52c 44%,
      #fbba2f 59%,
      #fdc033 78%,
      #fdc033 78%,
      #ffc837 100%
    );
}
</style>