var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conGrad"},[(_vm.serviceData.top)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-sm-4":"","pa-md-10":"","pa-lg-16":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","lg11":"","xl8":"","align-self-center":"","py-4":"","px-sm-0":"","px-md-5":"","px-lg-16":""}},[_c('v-card',{attrs:{"min-height":_vm.$vuetify.breakpoint.name == 'xl' ? '80vh' : '60vh',"flat":"","dark":"","color":"transparent"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-md-12":"","pt-xl-16":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","lg6":"","pl-3":"","pr-3":"","pl-sm-0":"","pr-sm-4":"","pl-md-0":"","pr-md-8":"","pt-12":"","pt-sm-10":"","pt-md-0":"","pt-xl-16":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","py-5":"","align-self-center":"","text-left":""}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'sm' ||
                    _vm.$vuetify.breakpoint.name == 'xs'
                      ? 'con1xs'
                      : 'con1'},[_vm._v(_vm._s(_vm.serviceData.top.title))])]),_c('v-flex',{attrs:{"pb-4":"","xs12":"","align-self-center":"","text-left":""}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'sm' ||
                    _vm.$vuetify.breakpoint.name == 'xs'
                      ? 'con7xs'
                      : _vm.$vuetify.breakpoint.name == 'md'
                      ? 'con7md'
                      : 'con7'},[_vm._v(" "+_vm._s(_vm.serviceData.top.topCaption)+" ")])]),_c('v-flex',{attrs:{"xs12":"","pb-6":"","align-self-center":"","text-justify":""}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'sm' ||
                    _vm.$vuetify.breakpoint.name == 'xs'
                      ? 'con2xs'
                      : 'con22',domProps:{"innerHTML":_vm._s(_vm.serviceData.top.description)}})])],1)],1),(_vm.serviceData.top.image)?_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":"","lg6":"","align-self-center":"","pt-xl-16":"","pl-md-8":"","pl-3":"","pr-3":""}},[_c('v-img',{attrs:{"src":_vm.webpSupported && _vm.item.image.split('.').pop() !== 'svg'? _vm.mediaURL+_vm.serviceData.top.image :_vm.mediaURLold+_vm.serviceData.top.image}})],1):_vm._e()],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }